<template lang="pug">
#floating-akos.card.visible-md.visible-lg
  #floating-akos-bell.rounded
    i.fas.fa-bell
  .card-horizontal
    .card-body.text-center
      img(
        :src="akos"
        alt="Akos Toth - Leiter Kundenberatung"
        class="rounded-circle"
        )
      br
      strong Akos Toth
    .card-body
      h4.card-title Haben Sie Fragen?
      .card-text
        ul.fa-ul
          li
            i(class="fas fa-li fa-envelope-square")
            a(href="mailto:akos.toth@pludoni.de") akos.toth@pludoni.de
          li
            i(class="fas fa-li fa-phone-square")
            | 0351 / 28792370
          li
            i(class="fab fa-li fa-xing-square")
            a(href="https://www.xing.com/profile/Akos_Toth2" target="_blank" rel="noopener") XING
        a(href="/mitmachen" target= "_blank" class= "btn btn-secondary") Mitmachen
</template>

<script>

import akos from '../../../assets/images/akos.jpg'

export default {
  data() {
    return {
      akos
    };
  }
}
</script>

<style scoped lang="scss">

@import './../../../assets/stylesheets/jobwert_variables';

#floating-akos {
  border-radius: 0;
  border: 1px solid white;
  background-color: $jobwert-red;
  position: fixed !important;
  top: 100px;
  right: -280px;
  width: 300px;
  z-index: 9999;
  border-radius: 10px 0 0 10px;
  color: white;
  overflow: visible;
  @media screen and (min-width: 768px) {
    &--affix {
      visibility: visible;
    }
  }
  transition: right 0.3s ease-out;
  &:hover {
    height: auto;
    right: -1px;
  }
  #floating-akos-bell {
    background-color: $jobwert-blue;
    padding: 8px;
    color: white;
    position: absolute;
    top: -8px;
    left: -8px;
  }
  ul {
    a {
      text-decoration: underline;
      color: white;
      transition: all .3s;
      &:hover {
        color: darken(white, 10%);
      }
    }
  }
  img {
    width: 50px;
    height: auto;
    border-radius: 25px;
  }
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}
</style>
