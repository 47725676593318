
#floating-akos.card.visible-md.visible-lg
  #floating-akos-bell.rounded
    i.fas.fa-bell
  .card-horizontal
    .card-body.text-center
      img(
        :src="akos"
        alt="Akos Toth - Leiter Kundenberatung"
        class="rounded-circle"
        )
      br
      strong Akos Toth
    .card-body
      h4.card-title Haben Sie Fragen?
      .card-text
        ul.fa-ul
          li
            i(class="fas fa-li fa-envelope-square")
            a(href="mailto:akos.toth@pludoni.de") akos.toth@pludoni.de
          li
            i(class="fas fa-li fa-phone-square")
            | 0351 / 28792370
          li
            i(class="fab fa-li fa-xing-square")
            a(href="https://www.xing.com/profile/Akos_Toth2" target="_blank" rel="noopener") XING
        a(href="/mitmachen" target= "_blank" class= "btn btn-secondary") Mitmachen
